<template>
  <Layout :tituloPagina="`Network | Routers | Edición | ${router.nombre} | Interfaces LAN | ${modo == 'nuevo' ? 'Nuevo' : 'Edición'}`">
    <div class="row">
      <div class="col-lg-7 col-md-8">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title text-truncate w-100">
              {{ modo == 'nueva' ? 'Nueva' : 'Edición de ' }}
              interfaz LAN del router {{ router.nombre }}
            </h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      onclick="return false"
                      @click="modo == 'nueva' ? guardar() : actualizar()"
                      v-if="!bandera_spinner"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nueva' ? 'Guardar' : 'Actualizar' }}
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      {{ modo == 'nueva' ? 'Guardar' : 'Actualizar' }}
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="cerrar()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row mb-4" v-show="lan.id != null">
              <div class="col-sm-3">
                <label class="text-truncate w-100">ID</label>
              </div>
              <div class="col-sm-3">
                <input
                  type="text"
                  class="form-control"
                  v-model="lan.id"
                  placeholder="ID"
                  readonly
                  />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3">
                <label class="text-truncate w-100">Descripción</label>
              </div>
              <div class="col-sm-9">
                <textarea
                  rows="3"
                  class="form-control"
                  v-model="lan.descripcion"
                  placeholder="LAN para clientes"
                  ref="descripcion_lan"
                ></textarea>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-md-3">
                <label class="text-truncate w-100">
                  Administración de clientes
                </label>
              </div>
              <div class="col-md-5">
                <select class="form-select" v-model="lan.pppoe">
                  <option value="0">Por direcciones IP estáticas</option>
                  <option value="1">Por servicio PPPoE</option>
                </select>
              </div>
              <div class="col-md-4">
                <!-- <div class="form-check-success form-check-switch-right form-switch form-switch-lg">
                  <input
                    :checked="lan.ht"
                    @change="lan.ht = !lan.ht"
                    class="form-check-input"
                    type="checkbox"
                    id="historialTrafico"
                  />
                  <label class="form-check-label" for="historialTrafico">
                    Registrar historial de tráfico
                  </label>
                </div> -->
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-md-3">
                <label>Interfaz</label> <br />
                <select
                  class="form-select"
                  v-model="lan.id_interfaz"
                  ref="id_interfaz"
                >
                  <option :value="null">Seleccionar</option>
                  <option
                    v-for="interfaz in interfaces"
                    :key="interfaz.id"
                    :value="interfaz.id"
                    v-show="interfaz.modo != 'WAN'"
                  >
                    {{ interfaz.name }}
                  </option>
                </select>
              </div>
              <div class="col-md-7">
                <label>
                  Dirección IP
                </label>
                <br />
                <input
                  type="text"
                  class="form-control"
                  v-model="lan.direccion_ip"
                  ref="direccion_ip"
                  placeholder="192.168.1.1/24"
                />
              </div>
              <div class="col-md-2">
                <label>NAT</label>
                <select class="form-select" v-model="lan.nat">
                  <option value="0">No</option>
                  <option value="1">Si</option>
                </select>
              </div>
            </div>
            <br />
            <div class="row" v-show="lan.pppoe == true">
              <label class="col-sm-3">Nombre del servicio PPPoE</label>
              <div class="col-sm-7">
                <input
                  type="text"
                  class="form-control"
                  v-model="lan.nombre_servicio_pppoe"
                  ref="nombre_servicio_pppoe"
                  placeholder="servicio1"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="text-right">
          <div class="btn-group">
            <button class="btn btn-secondary" @click="atras()">
              <i class="mdi mdi-chevron-left"></i>
              Atrás
            </button>
            <button
              class="btn btn-success"
              @click="modo == 'nueva' ? guardar() : actualizar()"
              :disabled="bandera_spinner"
            >
              <i
                class="mdi"
                :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
              ></i>
              {{ modo == 'nueva' ? 'Guardar' : 'Actualizar' }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main"
import RouterSrv from '@/services/RouterSrv.js'
import LanRouterSrv from '@/services/LanRouterSrv.js'
export default {
  name: 'EdicionLanRouter',
  components: { Layout },
  data() {
    return {
      baseUrl: window.location.origin, 
      idRouter: null,
      router: { id: null },
      lan: {
        id: null,
        descripcion: '',
        id_interfaz: null,
        id_router: null,
        direccion_ip: '',
        nat: 0,
        pppoe: 0,
        nombre_servicio_pppoe: '',
        ht: 0,
        ht_sinc: 1
      },
      lanIni: null,
      modo: 'nueva',
      interfaces: [],
      bandera_spinner: false
    }
  },

  watch: {
    'lan.ht': function(newVar, oldVar) {
      this.lan.ht_sinc = false
    }
  },

  created: function() {
    var self = this

    self.lanIni = Object.assign({}, self.lan)

    if (this.$route.path.indexOf('edicion') != -1) self.modo = 'edicion'

    // Carga de la relación del ISP con el router especificado
    this.idRouter = this.$route.params.id

    if (self.modo == 'edicion') {
      self.cargarInterfaz()
    }

    this.cargarRouter()
    this.refrescarInterfaces()
  },

  methods: {
    actualizar: function() {
      var self = this

      var lan = Object.assign({}, this.lan)
      lan.id_router = this.idRouter

      self.bandera_spinner = true

      if (lan.descripcion == '') {
        iu.msg.warning('Es necesaria una descripción para la LAN')
        self.$refs.descripcion_lan.focus()
        self.bandera_spinner = false
        return
      }

      if (lan.id_interfaz == null) {
        iu.msg.warning('Es necesario seleccionar una interfaz LAN')
        self.$refs.id_interfaz.focus()
        self.bandera_spinner = false
        return
      }

      if (lan.direccion_ip == '') {
        iu.msg.warning('Es necesaria una dirección IP con CIDR (ejemplo: 172.16.1.1/24)')
        self.$refs.direccion_ip.focus()
        self.$refs.direccion_ip.select()
        self.bandera_spinner = false
        return
      }

      if (lan.pppoe == true && lan.nombre_servicio_pppoe == '') {
        iu.msg.warning('Es necesario un nombre para el servicio PPPoE')
        self.$refs.nombre_servicio_pppoe.focus()
        self.bandera_spinner = false
        return
      }
      
      LanRouterSrv.actualizar(lan).then(response => {
        var id = response.data
        iu.msg.success('Se actualizó correctamente')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo guardar'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },
    cargarInterfaz: function() {
      var self = this

      LanRouterSrv.lanJSON(self.$route.params.id_lan).then(response => {
        Object.assign(self.lan, response.data)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    },
    guardar: function() {
      var self = this

      var lan = Object.assign({}, this.lan)
      lan.id_router = this.idRouter

      self.bandera_spinner = true

      if (lan.descripcion == '') {
        iu.msg.warning('Es necesaria una descripción para la LAN')
        self.$refs.descripcion_lan.focus()
        self.bandera_spinner = false
        return
      }

      if (lan.id_interfaz == null) {
        iu.msg.warning('Es necesario seleccionar una interfaz LAN')
        self.$refs.id_interfaz.focus()
        self.bandera_spinner = false
        return
      }

      if (lan.direccion_ip == '') {
        iu.msg.warning('Es necesaria una dirección IP con CIDR (ejemplo: 172.16.1.1/24)')
        self.$refs.direccion_ip.focus()
        self.$refs.direccion_ip.select()
        self.bandera_spinner = false
        return
      }

      if (lan.pppoe == true && lan.nombre_servicio_pppoe == '') {
        iu.msg.warning('Es necesario un nombre para el servicio PPPoE')
        self.$refs.nombre_servicio_pppoe.focus()
        self.bandera_spinner = false
        return
      }
      
      LanRouterSrv.guardar(lan).then(response => {
        iu.msg.success('Se guardó correctamente')

        if (self.$route.params.hotspot) sessionStorage.setItem('argusblack.hotspot.id_lan', response.data)

        self.limpiar()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo guardar'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    atras: function() {
      this.$router.go(-1)
    },

    cerrar: function() {
      this.atras()
    },

    cargarRouter: function(callback) {
      var self = this
      this.idRouter = this.$route.params.id

      return RouterSrv.routerJSON(this.idRouter).then(response => {
        self.router = response.data

        if (
          self.router.interfaces == undefined ||
          self.router.interfaces.length == 0
        ) {
          iu.msg.warning(
            'Conecta tu router a ArgusBlack para que se sincronicen las interfaces y puedas registrar las LAN'
          )
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    },

    limpiar: function() {
      this.lan = Object.assign({}, this.lanIni)
    },

    refrescarInterfaces: function() {
      var self = this

      RouterSrv.interfacesJSON(self.idRouter).then(response => {
        self.interfaces = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las interfaces'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    }
  },
}
</script>

<style scoped>

</style>